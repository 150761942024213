import React from "react"
import styled from "styled-components"

import { Row, Col, Card } from "react-bootstrap"
import automation from "../../assets/images/automation.svg"
import product from "../../assets/images/product-1.svg"
import clock from "../../assets/images/clock.svg"

const Hometopcards = () => {
  return (
    <Row
      className="g-0 "
      style={{
        background: "linear-gradient(0deg,#f9fcf6bf,#1b62c2)",
        "margin-top": "auto",
      }}
    >
      <Col sm={12} lg={4} className="p-2">
        <Card className="h-100">
          <Card.Img
            variant="top"
            src={automation}
            style={{ width: "100px", height: "100px", "align-self": "center" }}
          />
          <Card.Body>
            <Card.Title className="text-center">Managment</Card.Title>
            <Card.Text>
              Suphesticated Amazon Management System, It's equipped with many
              features that the Amazon dashboard lacks to provide.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={12} lg={4} className="p-2">
        <Card className="h-100">
          <Card.Img
            variant="top"
            src={product}
            style={{ width: "100px", height: "100px", "align-self": "center" }}
          />
          <Card.Body>
            <Card.Title className="text-center">Notifications</Card.Title>
            <Card.Text>
              Its alert and notification system will keep you alert of what is
              happening so that you can do what is most suitable.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={12} lg={4} className="p-2">
        <Card className="h-100">
          <Card.Img
            variant="top"
            src={clock}
            style={{ width: "100px", height: "100px", "align-self": "center" }}
          />
          <Card.Body>
            <Card.Title className="text-center">Suggestions</Card.Title>
            <Card.Text>
              It, also, suggests you what to do, Leaving your precious time to
              do what matters most for sustainable growing Amazon Business.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <LightDivider />
    </Row>
  )
}

const LightDivider = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwUzk5My40NiAxNDAgNjQwIDEzOSAwIDAgMCAwdjE0MHoiLz48L2c+PC9zdmc+);
  background-size: 100% 100px;
  bottom: 0;
  height: 100px;
  z-index: 1;
  background-position: center;
  background-size: cover;
  width: 100%;
  left: 0;
`

export default Hometopcards
